import React from 'react';
import { VIDEO_EVENTS } from 'playable/dist/src/constants';
import { get } from 'lodash';

type ErrorType = any;
interface EventEmitter {
  // TODO move from here
  on: (ev: VIDEO_EVENTS, handler: (err: ErrorType) => void) => void;
  off: (ev: VIDEO_EVENTS, handler: (err: ErrorType) => void) => void;
}

export interface PlayerErrorBiPayload {
  videoID: string;
  videoUrl: string;
  errorType: string;
  streamProvider: string;
  streamType: string;
  isLive: boolean;
}

interface Props {
  videoID: string;
  logFunction: (data: PlayerErrorBiPayload, error: Error) => void;
  eventEmitter: EventEmitter;
  engine: any; // TODO
  isLive: boolean;
}

export class ErrorLogger extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.subscribeToEvents();
  }

  componentWillUnmount() {
    this.unSubscribeFromEvents();
  }

  subscribeToEvents() {
    const { eventEmitter } = this.props;

    eventEmitter.on(VIDEO_EVENTS.ERROR, this.logError);
  }

  unSubscribeFromEvents() {
    const { eventEmitter } = this.props;

    eventEmitter.off(VIDEO_EVENTS.ERROR, this.logError);
  }

  logError = (error: ErrorType) => {
    const { videoID, engine, logFunction, isLive } = this.props;
    const videoUrl = get(engine, 'attachedAdapter.currentUrl', null);

    const { errorType, streamProvider, streamType, errorInstance } = error;

    logFunction(
      {
        videoID,
        videoUrl,
        errorType,
        streamProvider,
        streamType,
        isLive,
      },
      errorInstance,
    );
  };

  render() {
    return null;
  }
}

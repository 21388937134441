import React from 'react';
import { connect } from 'react-redux';

import {
  StatisticLogger,
  PlaybackSuccessLogger,
} from '@wix/wix-vod-shared/components';
import { ErrorLogger, PlayerErrorBiPayload } from './error-logger';
import { logBi } from '../../../../../worker/actions/bi';
import { useErrorMonitor } from '@wix/yoshi-flow-editor';
import { RootState } from '../../../../../redux/root-state';
import { pick } from 'lodash';

interface OwnProps {
  isAutoplayed: boolean;
  isEmbed?: boolean;
  isLive?: boolean;
  isVideoPlaying: boolean;
  engine: object;
  eventEmitter: { on: () => void; off: () => void };
  channelData: { id: string };
  videoItem: { id: string };
}
interface StateProps {}
interface DispatchProps {
  logBi: (eventName: string, payload: unknown) => void;
}
const Loggers: React.FC<OwnProps & StateProps & DispatchProps> = ({
  isAutoplayed,
  isEmbed = false,
  isLive = false,
  isVideoPlaying,
  engine,
  eventEmitter,
  channelData,
  videoItem,
  logBi,
}) => {
  const errorMonitor = useErrorMonitor();

  const logPlaybackTry = (data: object) => {
    logBi('player-play-requested', {
      ...data,
      isAutoplay: isAutoplayed,
    });
  };

  const logPlaybackSuccess = (data: object) => {
    logBi('player-play-done', {
      ...data,
      isAutoplay: isAutoplayed,
    });
  };

  const logError = (data: PlayerErrorBiPayload, errorInstance: Error) => {
    logBi('player-error', data);
    errorMonitor.captureException(new Error(`Player error ${data.errorType}`), {
      contexts: {
        'Player Error': pick(errorInstance, [
          'url',
          'type',
          'details',
          'fatal',
          'response',
        ]),
        'Event data': { ...data },
      },
    });
  };

  const logStatistic = (data: object) => {
    logBi('player.debug.info', {
      ...data,
      isAutoplay: isAutoplayed,
    });
  };

  return (
    <section>
      <StatisticLogger
        videoID={videoItem.id}
        isEmbed={isEmbed}
        isLive={isLive}
        channelID={channelData.id}
        engine={engine}
        logFunction={logStatistic}
        shouldSendBI={isVideoPlaying}
      />
      <PlaybackSuccessLogger
        videoID={videoItem.id}
        engine={engine}
        eventEmitter={eventEmitter}
        logTryFunction={logPlaybackTry}
        logSuccessFunction={logPlaybackSuccess}
        channelID={channelData.id}
      />
      <ErrorLogger
        engine={engine}
        eventEmitter={eventEmitter}
        logFunction={logError}
        videoID={videoItem.id}
        isLive={isLive}
      />
    </section>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(null, {
  logBi,
})(Loggers);
